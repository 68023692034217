// ApiContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';


// Create the context
export const QuestionsContext = createContext({
  allQuestionsAnswered: false,
  setAllQuestionsAnswered: () => {}
});

// Create a custom hook for using this context
export const useQuestionsContext = () => useContext(QuestionsContext);

// Provider component
export const QuestionsProvider = ({ children }) => {
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

  return (
    <QuestionsContext.Provider value={{ allQuestionsAnswered, setAllQuestionsAnswered }}>
      {children}
    </QuestionsContext.Provider>
  );
};



// ScreenSize Context
const ScreenSizeContext = createContext();
export const useScreenSize = () => useContext(ScreenSizeContext);
export const ScreenSizeProvider = ({ children }) => {
    const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useEffect(() => {
        const handleResize = () => setScreenSize({ width: window.innerWidth, height: window.innerHeight });
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <ScreenSizeContext.Provider value={screenSize}>{children}</ScreenSizeContext.Provider>;
};

// UserContext, OtherContexts, etc...
// Define other contexts following the same pattern as above

