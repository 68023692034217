import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useScreenSize } from "../../contexts/ApiContext";
import { RxHamburgerMenu } from "react-icons/rx";

// Import other icons as needed


const CustomNav = ({ li }) => {
    const screenSize = useScreenSize();
    const [isNavExpanded, setIsNavExpanded] = useState(screenSize.width > 1200);

    useEffect(() => {
        setIsNavExpanded(screenSize.width > 1200);
    }, [screenSize]);

    const toggleNav = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    const topLinks = li.filter(item => item.length < 4 || item[3] === "top");
    const bottomLinks = li.filter(item => item.length === 4 && item[3] === "bottom");

    const renderLinks = (links) => (
        links.map((item, i) => {
            const Icon = item[1]; // Icon component
            return (
                <li key={i} className="navbar__li-box">
                    {typeof item[2] === 'function' ? (
                        <button onClick={item[2]} className="navbar__action">
                            <Icon className="navbar__icon" />
                            <span className="navbar__li" style={{ display: isNavExpanded ? "inline" : "none" }}>
                                {item[0]}
                            </span>
                        </button>
                    ) : (
                        <Link to={item[2]}>
                            <Icon className="navbar__icon" />
                            <span className="navbar__li" style={{ display: isNavExpanded ? "inline" : "none" }}>
                                {item[0]}
                            </span>
                        </Link>
                    )}
                </li>
            );
        })
    );

    return (
        <nav className={`navbar-menu ${isNavExpanded ? 'expanded' : ''}`} style={{ width: isNavExpanded ? 180 : 60 }}>            <div className="burger" onClick={toggleNav}>
            <RxHamburgerMenu className="burger-icon"/>
        </div>
            <ul className="navbar__list">
                {renderLinks(topLinks)}
            </ul>
            <div className="navbar__list-bottom-container">
                <ul className="navbar__list navbar__list-bottom">
                    {renderLinks(bottomLinks)}
                </ul>
            </div>
        </nav>
    );
};

export default CustomNav;
