// Questions.js
import React, { useState, useEffect } from 'react';
import '../scss/liia.scss';
import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
import config from '../amplifyconfiguration.json';
import { listAssessments } from "../constants/graphqlQueries";
import { useQuestionsContext } from '../contexts/ApiContext';
import SurveyComponent from './Survey';


Amplify.configure(config);

export const client = generateClient();


const Questions = () => {
    const { setAllQuestionsAnswered } = useQuestionsContext(); // Accessing context
    const [assessments, setAssessments] = useState([]);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [surveyModel, setSurveyModel] = useState(null);

    const loadAssessmentData = async () => {
        try {
            const { data } = await client.graphql({ query: listAssessments });
            console.log("Assessment Data:", data.listAssessments.items);
            setAssessments(data.listAssessments.items);
        } catch (error) {
            console.error('Error loading assessments:', error);
        }
    };

        const transformToSurveyJson = (assessmentData) => {
            if (Array.isArray(assessmentData.assessmentQuestions.items)) {
                let surveyPages = [{
                    name: "page1",
                    elements: assessmentData.assessmentQuestions.items.map(question => {
                        // Determine question type based on question data
                        let questionType = "radiogroup"; // Default type
                        // Example: Adjust this logic based on your actual data
                        if (question.surveyOptions && question.surveyOptions.type === "LikertScale") {
                            questionType = "radiogroup"; // or another appropriate type
                        }
                        // Add other conditions for different types as needed
        
                        return {
                            type: questionType,
                            name: question.id,
                            title: question.questionText,
                            choices: question.answerOptions
                        };
                    })
                }];
        
                return {
                    title: assessmentData.assessmentName || "Assessment",
                    showProgressBar: "bottom",
                    pages: surveyPages
                };
            } else {
                // Return a default or empty survey JSON if assessmentQuestions is not available
                return {
                    title: "No questions available",
                    pages: []
                };
            }
        };
        
         
    // Load assessments on component mount
    useEffect(() => {
        
        loadAssessmentData();
        
    }, []);

    // Transform to Survey JSON
    const surveyJson = selectedAssessment ? transformToSurveyJson(selectedAssessment) : null;

    // Rendering the component
    return (
        <div className="container mt-5 questionContainer">
            {assessments.length === 0 ? (
                <div>Loading assessments...</div>
            ) : (
                <>
                    <select
                        value={selectedAssessment ? selectedAssessment.id : ''}
                        onChange={(e) => setSelectedAssessment(assessments.find(assessment => assessment.id === e.target.value))}
                    >
                        <option value="">Select an Assessment</option>
                        {assessments.map(assessment => (
                            <option key={assessment.id} value={assessment.id}>{assessment.assessmentName}</option>
                        ))}
                    </select>

                    {selectedAssessment && <h2>{selectedAssessment.assessmentType}</h2>}

                    {surveyJson && <SurveyComponent json={surveyJson} />}
                </>
            )}
        </div>
    );
};

export default Questions;
