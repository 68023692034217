// routes.js
import Home from './components/Home';
import Questions from './components/Questions';
import Footer from './components/Footer'; // Example public component
import AssessmentList from './components/AssessmentList';
import CompanyForm from './components/forms/CompanyForm';
import UserForm from './components/forms/UserForm';
import Profile from './components/Profile';
import CompanyOnboarding from './components/forms/CompanyOnboarding';

export const publicRoutes = [
  { path: "/", element: <Home /> },
  { path: "/public-page", element: <Footer /> },
  { path: "/assessment-list", element: <AssessmentList />},
  // more public routes...
];

export const protectedRoutes = [
  { path: "/questions", element: <Questions /> },
  { path: "/profile", element: <Profile /> },
  { path: "/profile/company", element: <CompanyForm />},
  { path: "/profile/user", element: <UserForm />},
  { path: "/profile/onboarding", element: <CompanyOnboarding />},


  // more protected routes...
];
