// Survey.js
import React from 'react';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import * as SurveyCore from "survey-core"; // Import for localization
import * as SurveyTheme from "survey-core/themes";
import "survey-core/defaultV2.min.css";
import "../scss/liia.scss"

// Customize progress text
SurveyCore.surveyLocalization.locales["en"].progressText = "Question {0} of {1}";

function SurveyComponent({ json }) {
    const survey = new Model(json);
    survey.applyTheme(SurveyTheme.LayeredLightPanelless);
    survey.onComplete.add((sender, options) => {
        console.log(JSON.stringify(sender.data, null, 3));
    });

    return <Survey model={survey} />;
}

export default SurveyComponent;
