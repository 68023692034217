import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

// Custom hooks for responsive design
export const useMediaQuery = (query) => {
    const [matches, setMatches] = React.useState(false);
    React.useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);
    return matches;
};

const MasonryLayout = (props) => {
    const {
        animate,
        gutter,
        vgutter,
        components,
        mediaQuerySmall,
        mediaQueryMedium,
        mediaQueryBig,
        columnsSmall,
        columnsMedium,
        columnsBig,
        style,
    } = props;

    const isSmall = useMediaQuery("(min-width: " + mediaQuerySmall + "px)");
    const isMedium = useMediaQuery("(min-width: " + mediaQueryMedium + "px)");
    const isBig = useMediaQuery("(min-width: " + mediaQueryBig + "px)");

    const getColumns = () => {
        if (isBig) {
            return columnsBig;
        } else if (isMedium) {
            return columnsMedium;
        } else if (isSmall) {
            return columnsSmall;
        } else {
            return 4; // Default column count
        }
    };

    // Hover Variants
    const hoverVariants = {
        hover: {
            scale: 1.05,
            transition: {
                scale: {
                    type: "spring",
                    duration: 0.3,
                    stiffness: 500,
                    damping: 20
                },
                opacity: {
                    duration: 0.3,
                    delay: 0.15 // Delay opacity change to give time for card to expand
                }
            }
        },
        rest: {
            scale: 1,
            transition: {
                duration: 0.7
            }
        }
    };
    
    return (
        <motion.div style={{ ...style, padding: '8px', width: '100%', minWidth: '390px', height: '100%', boxSizing: 'border-box' }}>
            <motion.div className="masonry-with-flex" style={{ columnGap: gutter, columnCount: getColumns() }}>
                {React.Children.map(components, (child, index) => (
                    <motion.figure
                        layout={animate}
                        initial={animate ? "offscreen" : false}
                        whileInView={animate ? "onscreen" : "offscreen"}
                        whileHover={hoverVariants.hover} // Apply hover effect
                        style={{ margin: 0, display: 'grid', gridTemplateRows: '1fr auto', marginBottom: vgutter, breakInside: 'avoid' }}
                        key={index}
                    >
                        {React.cloneElement(child, { width: '100%' })}
                    </motion.figure>
                ))}
            </motion.div>
        </motion.div>
    );
};

// Default props for the MasonryLayout component
MasonryLayout.defaultProps = {
    gutter: 10,
    vgutter: 10,
    columnsSmall: 1,
    columnsMedium: 3,
    columnsBig: 5,
    mediaQuerySmall: 390,
    mediaQueryMedium: 750,
    mediaQueryBig: 900,
    animate: true,
};

// Prop Types
MasonryLayout.propTypes = {
    animate: PropTypes.bool,
    gutter: PropTypes.number,
    vgutter: PropTypes.number,
    components: PropTypes.array.isRequired,
    mediaQuerySmall: PropTypes.number,
    mediaQueryMedium: PropTypes.number,
    mediaQueryBig: PropTypes.number,
    columnsSmall: PropTypes.number,
    columnsMedium: PropTypes.number,
    columnsBig: PropTypes.number,
    style: PropTypes.object
};

export default MasonryLayout;
