/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAssessments = /* GraphQL */ `
  mutation CreateAssessments(
    $input: CreateAssessmentsInput!
    $condition: ModelAssessmentsConditionInput
  ) {
    createAssessments(input: $input, condition: $condition) {
      id
      assessmentName
      assessmentType
      assessmentDescription
      assessmentSlug
      assessmentOverview
      assessmentCompletionTime
      assessmentSrc
      assessmentQuestions {
        nextToken
        __typename
      }
      assessmentResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAssessments = /* GraphQL */ `
  mutation UpdateAssessments(
    $input: UpdateAssessmentsInput!
    $condition: ModelAssessmentsConditionInput
  ) {
    updateAssessments(input: $input, condition: $condition) {
      id
      assessmentName
      assessmentType
      assessmentDescription
      assessmentSlug
      assessmentOverview
      assessmentCompletionTime
      assessmentSrc
      assessmentQuestions {
        nextToken
        __typename
      }
      assessmentResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAssessments = /* GraphQL */ `
  mutation DeleteAssessments(
    $input: DeleteAssessmentsInput!
    $condition: ModelAssessmentsConditionInput
  ) {
    deleteAssessments(input: $input, condition: $condition) {
      id
      assessmentName
      assessmentType
      assessmentDescription
      assessmentSlug
      assessmentOverview
      assessmentCompletionTime
      assessmentSrc
      assessmentQuestions {
        nextToken
        __typename
      }
      assessmentResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createQuestions = /* GraphQL */ `
  mutation CreateQuestions(
    $input: CreateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    createQuestions(input: $input, condition: $condition) {
      id
      assessmentId
      questionText
      answerOptions
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      assessmentsAssessmentQuestionsId
      __typename
    }
  }
`;
export const updateQuestions = /* GraphQL */ `
  mutation UpdateQuestions(
    $input: UpdateQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    updateQuestions(input: $input, condition: $condition) {
      id
      assessmentId
      questionText
      answerOptions
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      assessmentsAssessmentQuestionsId
      __typename
    }
  }
`;
export const deleteQuestions = /* GraphQL */ `
  mutation DeleteQuestions(
    $input: DeleteQuestionsInput!
    $condition: ModelQuestionsConditionInput
  ) {
    deleteQuestions(input: $input, condition: $condition) {
      id
      assessmentId
      questionText
      answerOptions
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      assessmentsAssessmentQuestionsId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      userEmail
      firstName
      lastName
      userPhone
      userMobile
      userCompany {
        id
        cognitoUserId
        companyName
        companyType
        sector
        country
        website
        phoneNumber
        turnoverBracket
        companyHubspotId
        createdAt
        updatedAt
        owner
        __typename
      }
      companyId
      userTitle
      linkedIn
      userProfilePic
      hubspotId
      userResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      userEmail
      firstName
      lastName
      userPhone
      userMobile
      userCompany {
        id
        cognitoUserId
        companyName
        companyType
        sector
        country
        website
        phoneNumber
        turnoverBracket
        companyHubspotId
        createdAt
        updatedAt
        owner
        __typename
      }
      companyId
      userTitle
      linkedIn
      userProfilePic
      hubspotId
      userResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoUserId
      userEmail
      firstName
      lastName
      userPhone
      userMobile
      userCompany {
        id
        cognitoUserId
        companyName
        companyType
        sector
        country
        website
        phoneNumber
        turnoverBracket
        companyHubspotId
        createdAt
        updatedAt
        owner
        __typename
      }
      companyId
      userTitle
      linkedIn
      userProfilePic
      hubspotId
      userResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      cognitoUserId
      companyName
      companyType
      sector
      country
      website
      phoneNumber
      turnoverBracket
      companyHubspotId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      cognitoUserId
      companyName
      companyType
      sector
      country
      website
      phoneNumber
      turnoverBracket
      companyHubspotId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      cognitoUserId
      companyName
      companyType
      sector
      country
      website
      phoneNumber
      turnoverBracket
      companyHubspotId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createResults = /* GraphQL */ `
  mutation CreateResults(
    $input: CreateResultsInput!
    $condition: ModelResultsConditionInput
  ) {
    createResults(input: $input, condition: $condition) {
      id
      cognitoUserId
      user {
        id
        cognitoUserId
        userEmail
        firstName
        lastName
        userPhone
        userMobile
        companyId
        userTitle
        linkedIn
        userProfilePic
        hubspotId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      userId
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      assessmentId
      dateCompleted
      assessmentAnswers
      assessmentScore
      maturityLevel
      executiveSummary
      assessmentSuggestions
      createdAt
      updatedAt
      assessmentsAssessmentResultsId
      userUserResultsId
      owner
      __typename
    }
  }
`;
export const updateResults = /* GraphQL */ `
  mutation UpdateResults(
    $input: UpdateResultsInput!
    $condition: ModelResultsConditionInput
  ) {
    updateResults(input: $input, condition: $condition) {
      id
      cognitoUserId
      user {
        id
        cognitoUserId
        userEmail
        firstName
        lastName
        userPhone
        userMobile
        companyId
        userTitle
        linkedIn
        userProfilePic
        hubspotId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      userId
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      assessmentId
      dateCompleted
      assessmentAnswers
      assessmentScore
      maturityLevel
      executiveSummary
      assessmentSuggestions
      createdAt
      updatedAt
      assessmentsAssessmentResultsId
      userUserResultsId
      owner
      __typename
    }
  }
`;
export const deleteResults = /* GraphQL */ `
  mutation DeleteResults(
    $input: DeleteResultsInput!
    $condition: ModelResultsConditionInput
  ) {
    deleteResults(input: $input, condition: $condition) {
      id
      cognitoUserId
      user {
        id
        cognitoUserId
        userEmail
        firstName
        lastName
        userPhone
        userMobile
        companyId
        userTitle
        linkedIn
        userProfilePic
        hubspotId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      userId
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      assessmentId
      dateCompleted
      assessmentAnswers
      assessmentScore
      maturityLevel
      executiveSummary
      assessmentSuggestions
      createdAt
      updatedAt
      assessmentsAssessmentResultsId
      userUserResultsId
      owner
      __typename
    }
  }
`;
