import React, { useState, useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { getUser } from '../../graphql/queries'; // Import your GraphQL queries and mutations
import { createUser, updateUser } from '../../graphql/mutations'; // Import your GraphQL queries and mutations
import Header from '../Header';

const UserForm = () => {
    const [userRecord, setUserRecord] = useState({
        firstName: '',
        lastName: '',
        userPhone: '',
        userMobile: '',
        userTitle: '',
        linkedIn: '',
        userProfilePic: '',
        hubspotId: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const API = generateClient();

    useEffect(() => {
        handleFetchAndSaveUserRecord();
    }, []);

    const handleFetchAndSaveUserRecord = async () => {
        try {
            const attributes = await fetchUserAttributes();
            const cognitoUserId = attributes.sub;
    
            let userData = await API.graphql({ query: getUser, variables: { id: cognitoUserId } });
            let user = userData.data.getUser;
    
            if (!user) {
                const userEmail = attributes.email;
                user = await API.graphql({ query: createUser, variables: { input: { id: cognitoUserId, cognitoUserId, userEmail } } });
                user = user.data.createUser;
            }
    
            setUserRecord({ ...userRecord, ...user });
        } catch (err) {
            console.error('Error handling user record:', err);
            setError(`Error: ${err.message || JSON.stringify(err)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        setUserRecord({ ...userRecord, [e.target.name]: e.target.value });
    };

    const handleUpdateUser = async (e) => {
        e.preventDefault();
        try {
            if (!userRecord.id) {
                throw new Error("User ID is missing. Cannot update user.");
            }
    
            const updateUserInput = {
                id: userRecord.id,
                cognitoUserId: userRecord.cognitoUserId, // Since it's a required field in your schema
                userEmail: userRecord.userEmail, // Also a required field
                firstName: userRecord.firstName,
                lastName: userRecord.lastName,
                userPhone: userRecord.userPhone,
                userMobile: userRecord.userMobile,
                // userCompany: userRecord.userCompany, // Excluded due to relationship handling
                companyId: userRecord.companyId, // Include if it's part of the update
                userTitle: userRecord.userTitle,
                linkedIn: userRecord.linkedIn,
                userProfilePic: userRecord.userProfilePic,
                hubspotId: userRecord.hubspotId,
                // userResults: userRecord.userResults, // Excluded due to relationship handling
            };
    
            await API.graphql({ query: updateUser, variables: { input: updateUserInput } });
            // Handle response or state update as needed
        } catch (err) {
            console.error('Error updating user record:', err);
            setError(`Error: ${err.message || JSON.stringify(err)}`);
        }
    };
    
    if (loading) {
        return <div className="container mt-4">Loading...</div>;
    }

    if (error) {
        return <div className="container mt-4 alert alert-danger">{error}</div>;
    }

    return (
        <>
        <Header
        pageTitle="Update User Profile"
        tagline="To  elevate your analysis please provide as much information as you feel comfortable with."    
    />"
        <div className="container mt-4">
            <h2>User Profile</h2>
            <form onSubmit={handleUpdateUser}>
                <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">First Name</label>
                    <input type="text" className="form-control" id="firstName" name="firstName" value={userRecord.firstName} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="lastName" className="form-label">Last Name</label>
                    <input type="text" className="form-control" id="lastName" name="lastName" value={userRecord.lastName} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="userPhone" className="form-label">Phone</label>
                    <input type="text" className="form-control" id="userPhone" name="userPhone" value={userRecord.userPhone} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="userMobile" className="form-label">Mobile</label>
                    <input type="text" className="form-control" id="userMobile" name="userMobile" value={userRecord.userMobile} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="userTitle" className="form-label">Title</label>
                    <input type="text" className="form-control" id="userTitle" name="userTitle" value={userRecord.userTitle} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="linkedIn" className="form-label">LinkedIn Profile</label>
                    <input type="text" className="form-control" id="linkedIn" name="linkedIn" value={userRecord.linkedIn} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="userProfilePic" className="form-label">Profile Picture URL</label>
                    <input type="text" className="form-control" id="userProfilePic" name="userProfilePic" value={userRecord.userProfilePic} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <label htmlFor="hubspotId" className="form-label">HubSpot ID</label>
                    <input type="text" className="form-control" id="hubspotId" name="hubspotId" value={userRecord.hubspotId} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <button type="submit" className="btn btn-primary">Update Profile</button>
                </div>
            </form>
        </div>
        </>
    );
};

export default UserForm;
