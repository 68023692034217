import React, { createContext, useContext, useState, useEffect } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';

export const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userSub, setUserSub] = useState(null); // New state for userSub

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const authUser = await Authenticator.currentAuthenticatedUser();
      setUser(authUser);
      setUserSub(authUser.attributes.sub); // Set the userSub
    } catch (error) {
      setUser(null);
      setUserSub(null); // Reset on error
    }
  };

  return (
    <AuthContext.Provider value={{ user, userSub, checkUser }}>
      {children}
    </AuthContext.Provider>
  );
};
