import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Header = ({ pageTitle, tagline }) => {
  const { user } = useAuthenticator();

  return (
    <header className={user ? "header-logged-in" : "header-logged-out"}>
      <nav className="nav" role="navigation">
        <div className="container nav-elements">
          <div className="branding">
            <a href="#home"><img id="liiaLogo" src="/images/liia_logo_black_350x178.png" alt="Logo - liia.io" /></a>
          </div>
          {/* <ul className="navbar">
            <li><a href="#home">home</a></li>
            <li><a href="#history">history</a></li>
            <li><a href="#products">products</a></li>
            <li><a href="#guarantee">guarantee</a></li>
            <li><a href="#people">people</a></li>
          </ul> */}
        </div>
      </nav>
      <div className="container tagline">
        <h1 className="headline">{pageTitle}</h1>
        {/* <p>
          {user ? (
            `Welcome back, ${user.username}!` // Customize based on user details
          ) : (
            'We support and encourage active and healthy lifestyles, by offering ethically sourced and eco-friendly nutritional products for the performance-driven athlete.'
          )}
        </p> */}
        <p>{tagline}</p>
      </div>
    </header>
  );
};

export default Header;
