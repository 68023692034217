import React, { useState, useContext } from 'react';
import './App.css';
import { Authenticator, View, Image, useTheme, Text, CheckboxField, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './scss/liia.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import { Amplify } from 'aws-amplify';
import { generateClient } from 'aws-amplify/api';
// import Questions from './components/Questions';
import ParticleBg from './components/ParticlesBg';
import { publicRoutes, protectedRoutes } from './routes';
import { QuestionsProvider } from './contexts/ApiContext';
import CustomNav from './components/NavbarVerticle/CustomNav';
import { ScreenSizeProvider } from './contexts/ApiContext';
import { RiDashboard3Line } from 'react-icons/ri'; // From react-icons/ri
import { PiGaugeLight, PiBuildingsLight, PiFlowArrowLight, PiLightning } from 'react-icons/pi'; // From react-icons/pi
import { GoPeople } from 'react-icons/go'; // From react-icons/go
import { RxBarChart } from 'react-icons/rx'; // From react-icons/rx (Assuming this is the correct library)
import { BsFiletypePdf } from 'react-icons/bs'; // From react-icons/bs
import { IoIosLogOut } from 'react-icons/io'; // From react-icons/io
import Header from './components/Header';
// import { AuthContextProvider } from './contexts/AuthContext';
// import CompanyForm from './components/forms/CompanyForm';
import { AuthContext } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import { HeaderContext, HeaderProvider } from './contexts/HeaderContext';


// Import other icons as needed

// Import other icons as needed



const client = generateClient();

export default function App() {

    const { user } = useContext(AuthContext);
    

    const AuthComponents = {
        Header() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Image
                        alt="Amplify logo"
                        src="./images/liia_logo_black_350x178.png"
                    />
                </View>
            );
        },

        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Text color={tokens.colors.neutral[80]}>
                        &copy; 2024 Liia (NZ) Limited | All Rights Reserved
                    </Text>
                </View>
            );
        },

        SignUp: {
            FormFields() {
                const { validationErrors } = useAuthenticator();

                return (
                    <>
                        <Authenticator.SignUp.FormFields />
                        <CheckboxField
                            errorMessage={validationErrors.acknowledgement}
                            hasError={!!validationErrors.acknowledgement}
                            name="acknowledgement"
                            value="yes"
                            label="I agree with the Terms & Conditions"
                        />
                    </>
                );
            },
        },
    };

    return (
        <div className="app">
                {/* <AuthContextProvider> */}

<HeaderProvider>

            <QuestionsProvider>



                {/* Authenticator Wrapper for Protected Routes */}
                <Authenticator
    initialState="signUp"
    components={AuthComponents}
    services={{
        async validateCustomSignUp(formData) {
            // ... validation logic
        },
    }}
>
    {({ signOut, user }) => (
        <>
        <UserProvider>
            <main>
                <div className="container">
                {/* <h1>Hello {user ? user.email : 'Guest'}</h1> */}
                {/* Protected Routes */}
                <Routes>
                    {protectedRoutes.map(route => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Routes>
                {/* Other authenticated content */}
                <ScreenSizeProvider>
                    <CustomNav
                        li={[
                            ["Assessments", RiDashboard3Line, "/assessment-list", "top"],
                            ["Results", RxBarChart, "/company", "top"],
                            ["Implications", PiFlowArrowLight, "/Implications", "top"],
                            ["Recommendations", PiLightning, "/manage-users", "top"],
                            ["Profile", GoPeople, "/profile", "bottom"],
                            ["Logout", IoIosLogOut, signOut, "bottom"],
                        ]}
                    />
                </ScreenSizeProvider>
                </div>

            </main>
            </UserProvider>
        </>
    )}
</Authenticator>

                                {/* Public Routes */}
                                <Routes>
                    {publicRoutes.map(route => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Routes>

                <ParticleBg />
            </QuestionsProvider>
            </HeaderProvider>
            {/* </AuthContextProvider> */}
        </div>


    );
}
