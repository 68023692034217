//CompanyOnboarding.js

import React, { useState, useContext } from 'react';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import * as SurveyCore from "survey-core";
import * as SurveyTheme from "survey-core/themes";
import "survey-core/defaultV2.min.css";
import "../../scss/liia.scss"; // Adjust the path as needed
import InfoOverlay from '../InfoOverlay';
import Header from '../Header';
import { useUser } from '../../contexts/UserContext'; // Adjust the path as needed
import { generateClient } from 'aws-amplify/api'; // Import generateClient
import { updateCompany } from '../../graphql/mutations'; // Adjust the path as needed



// Customize progress text
SurveyCore.surveyLocalization.locales["en"].progressText = "Step {0} of {1}";

const surveyJSON = {
    title: "Company Information",
    completedHtml: "<h3>Thank you for completing the onboarding process.</h3>",
    pages: [
        {
            name: "companyNamePage",
            elements: [
                {
                    type: "html",
                    html: "<quote> All reports are for a named company, what name would you like this to be?</quote>"
                },
                { 
                    type: "text", 
                    name: "companyName", 
                    title: "Company Name:", 
                    isRequired: true 
                }
            ]
        },
        {
            name: "companyDetailsPage",
            elements: [
                {
                    type: "html",
                    html: "<quote> Great! Now, tell us more about <span>{companyName}<span>.</quote>"
                },
                { 
                    type: "dropdown", 
                    name: "companyType", 
                    title: "What type of company is it?", 
                    choices: ["Startup", "Small Business", "Medium Enterprise", "Large Corporation", "Non-Profit", "Government"],
                    isRequired: true 
                },
                { 
                    type: "dropdown", 
                    name: "sector", 
                    title: "Which sector does it operate in?", 
                    choices: ["Technology", "Healthcare", "Finance", "Education", "Retail", "Manufacturing", "Other"],
                    isRequired: true 
                }
            ]
        },
        {
            name: "additionalInfoPage",
            elements: [
                {
                    type: "html",
                    html: "<quote>Ok, got that. We understand that <span>{companyName}</span> is a <span>{companyType}</span> operating in the <span>{sector}</span> sector. Just a few more details to help us serve you better.</quote>"
                },
                { 
                    type: "dropdown", 
                    name: "country", 
                    title: "In which country is {companyName} located?", 
                    choices: ["New Zealand", "Australia", "United Kingdom", "United States", "Other"],
                    isRequired: true 
                },
                { 
                    type: "dropdown", 
                    name: "turnoverBracket", 
                    title: "What is your company's annual turnover bracket?", 
                    choices: ["Less than $100k", "$100k - $500k", "$500k - $1M", "$1M - $5M", "$5M - $10M", "$10M - $50M", "More than $50M"],
                    isRequired: true 
                }
            ]
        }
    ],
    showProgressBar: "top",
    firstPageIsStarted: true,
    startSurveyText: "Start"
};

function SurveyComponent() {
    const [showOverlay, setShowOverlay] = useState(true);
    const { companyRecord, setCompanyRecord } = useUser();
    const API = generateClient(); // Create an API client instance
    const survey = new Model(surveyJSON);
    survey.applyTheme(SurveyTheme.LayeredLightPanelless);

    const handleSurveyCompletion = async (surveyData) => {
        try {
            if (!companyRecord || !companyRecord.id) {
                throw new Error("Company ID is missing. Cannot update company.");
            }

            const updatedCompanyData = {
                id: companyRecord.id,
                // Map the survey data to your company schema fields
                companyName: surveyData.companyName,
                companyType: surveyData.companyType,
                sector: surveyData.sector,
                country: surveyData.country,
                turnoverBracket: surveyData.turnoverBracket,
                // Add other fields as needed
            };

            const result = await API.graphql({
                query: updateCompany,
                variables: { input: updatedCompanyData }
            });
            setCompanyRecord(result.data.updateCompany); // Update the company record in the context
            console.log("Company record updated successfully");
        } catch (error) {
            console.error('Error updating company record:', error);
        }
    };

    survey.onComplete.add((sender, options) => {
        const surveyData = sender.data;
        handleSurveyCompletion(surveyData);
    });

    const handleOverlayClose = () => {
        setShowOverlay(false);
    };

    return (
        <>
            <Header
                pageTitle="Welcome to BizAssess by liia.io"
                tagline="To provide you with the most accurate and tailored recommendations for your business..."
            />
            <div>
                {showOverlay && <InfoOverlay onClose={handleOverlayClose} />}
                {!showOverlay && <Survey model={survey} />}
            </div>
        </>
    );
}

export default SurveyComponent;
