import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";
import defaultParticlesConfig from "./data/particlesConfigDefault.json";
import greenParticlesConfig from "./data/particlesConfigGreen.json";
import { useQuestionsContext } from "../contexts/ApiContext";

const ParticleBg = () => {
    const { allQuestionsAnswered } = useQuestionsContext();
    const [init, setInit] = useState(false);
    const [visible, setVisible] = useState(true); // State to control visibility

    // Initialize the particles engine
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    useEffect(() => {
        if (allQuestionsAnswered !== undefined) {
            // Fade out, change config, then fade in
            setVisible(false); // Start fade-out

            setTimeout(() => {
                // Wait for fade-out to complete
                setVisible(true); // Start fade-in with new config
            }, 200); // Corresponds to the fade-out animation duration
        }
    }, [allQuestionsAnswered]);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const options = useMemo(() => allQuestionsAnswered ? greenParticlesConfig : defaultParticlesConfig, [allQuestionsAnswered]);

    // Conditional CSS class based on visibility state
    const particlesContainerClass = visible ? 'particles-container fade-in' : 'particles-container fade-out';

    if (init) {
        return (
            <div className={particlesContainerClass}>
                <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />
            </div>
        );
    }
}

export default ParticleBg;
