import React, { useState, useEffect, useContext, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Header from './Header';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { motion, AnimatePresence } from 'framer-motion';
import MasonryLayout from '../constants/masonryLayout'
import Offcanvas from 'react-bootstrap/Offcanvas'
import BadgeFilterOffcanvas from './BadgeFilteredOffcanvas';

const columnsCountBreakPoints = { 350: 1, 750: 2, 900: 3 };


// Example items array (replace with your actual data)
const items = [
  {
    title: "Organizational Talent Management Maturity Assessment",
    description: "Identify and strengthen your organization's talent management areas, from recruitment to diversity, for improved strategic decision-making and enhanced performance.",
    readMoreLink: "#", // Replace with actual links
    startAssessmentLink: "#", // Replace with actual links
    badge1: "HR Health Index",
    badge2: "17 Min"
  },
  {
    title: "Employee Engagement and Satisfaction Survey",
    description: "Measure and enhance employee engagement and satisfaction to boost workplace productivity and morale. Measure and enhance employee engagement and satisfaction to boost workplace productivity and morale. Measure and enhance employee engagement and satisfaction to boost workplace productivity and morale. Measure and enhance employee engagement and satisfaction to boost workplace productivity and morale.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Employee Insight",
    badge2: "20 Min"
  },
  {
    title: "Work-Life Balance Evaluation",
    description: "Assess how effectively your organization supports work-life balance to improve employee well-being.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Workplace Harmony",
    badge2: "15 Min"
  },
  {
    title: "Leadership Skills Assessment",
    description: "Evaluate the leadership skills within your organization to enhance management effectiveness.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Leadership",
    badge2: "25 Min"
  },
  {
    title: "Innovation and Creativity Audit",
    description: "Measure your organization's capacity for innovation and creative problem-solving.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Innovation",
    badge2: "30 Min"
  },
  {
    title: "Diversity and Inclusion Index",
    description: "Gauge the level of diversity and inclusion in your workplace and identify areas for improvement.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Diversity",
    badge2: "22 Min"
  },
  {
    title: "Corporate Culture Analysis",
    description: "Understand your organization's culture to identify strengths and areas for cultural enhancement.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Culture",
    badge2: "18 Min"
  },
  {
    title: "Customer Service Excellence Survey",
    description: "Assess the quality of your customer service and identify ways to enhance customer satisfaction.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Customer Focus",
    badge2: "20 Min"
  },
  {
    title: "Digital Transformation Readiness",
    description: "Determine your organization's readiness for digital transformation and technology adoption.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Digital",
    badge2: "27 Min"
  },
  {
    title: "Employee Safety and Wellness Check",
    description: "Evaluate the safety protocols and wellness initiatives in your workplace for employee well-being.",
    readMoreLink: "#",
    startAssessmentLink: "#",
    badge1: "Safety",
    badge2: "15 Min"
  }
];



const AssessmentList = () => {
  const [badgeFilters, setBadgeFilters] = useState({});

  useEffect(() => {
    const uniqueBadges = [...new Set(items.map(item => item.badge1))];
    const initialFilters = {};
    uniqueBadges.forEach(badge => {
      initialFilters[badge] = false;
    });
    setBadgeFilters(initialFilters);
  }, []);

  const handleCheckboxChange = (badge) => {
    setBadgeFilters(prevFilters => ({
      ...prevFilters,
      [badge]: !prevFilters[badge]
    }));
  };

  const filteredItems = items.filter(item => {
    if (Object.values(badgeFilters).every(val => val === false)) {
      return true;
    }
    return badgeFilters[item.badge1];
  });

  const cardVariants = {
    hover: {
      scale: 1.05,
      transition: {
        scale: {
          duration: 0.3,
          ease: "easeInOut"
        }
      }
    },
    initial: {
      scale: 1
    }
  };

  const textVariants = {
    visible: { opacity: 1, transition: { duration: 0.3, delay: 0.1 } },
    hidden: { opacity: 0 }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hovered card index


  return (
    <>
      <Header
        pageTitle="Maturity Assessments"
        tagline="Please select an Assessment to start using BizAsssess by liia.io"
      />


      

      <div className="container-md">
      <BadgeFilterOffcanvas
        badgeFilters={badgeFilters}
        handleCheckboxChange={handleCheckboxChange}
      />


        <MasonryLayout
          gutter={15}
          vgutter={15}
          columnsSmall={1}
          columnsMedium={2}
          columnsBig={3}
          mediaQuerySmall={350}
          mediaQueryMedium={750}
          mediaQueryBig={900}
          animate={true}
          components={filteredItems.map((item, index) => (
            <motion.div
              key={index}
              className="card"
              variants={cardVariants}
              initial="initial"
              whileHover="hover"
              onHoverStart={() => setHoveredIndex(index)} // Set hovered index to current card index
              onHoverEnd={() => setHoveredIndex(null)} // Reset hovered index
            >
              <div className="card-body">
                <span className="badge rounded-pill text-bg-secondary">{item.badge1}</span>
                <span className="badge rounded-pill text-bg-info">{item.badge2}</span>
                <h5 className="card-title">{item.title}</h5>
                <motion.p
                  className="card-text"
                  variants={textVariants}
                  initial="initial"
                  animate={hoveredIndex === index ? "visible" : "hidden"} // Animate based on hover state
                >
                  {item.description}
                </motion.p>
                <a href={item.readMoreLink} className="btn btn-secondary">Read More</a>
                <a href={item.startAssessmentLink} className="btn btn-primary">Start Assessment</a>
              </div>
            </motion.div>
          ))}
        />  
      </div>
    </>
  );
};

export default AssessmentList;
