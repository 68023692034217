// BadgeFilterOffcanvas.js
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Offcanvas from 'react-bootstrap/Offcanvas';

const BadgeFilterOffcanvas = ({ badgeFilters, handleCheckboxChange }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="filters">
        Toggle Filters
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement="end" backdrop={false}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Badge Filters</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="badge-filters">
            {Object.keys(badgeFilters).map(badge => (
              <div key={badge}>
                <input
                  id={`checkbox-${badge}`}
                  type="checkbox"
                  checked={badgeFilters[badge]}
                  onChange={() => handleCheckboxChange(badge)}
                />
                <label htmlFor={`checkbox-${badge}`}>{badge}</label>
              </div>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default BadgeFilterOffcanvas;
