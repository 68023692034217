import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import "../scss/liia.scss"

const InfoOverlay = ({ onClose }) => {
    return (
        <div className="overlay">
            <div className="overlay-content onboarding">
                <div className="container">
                <h3>Why We Need Your Company's Details:</h3>
                {/* <p>To provide you with the most accurate and tailored recommendations for your business, we need a few key details about your company. Understanding the size of your company, the type of company, and the sector you operate in helps us to:</p> */}
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>1. Customize Your Experience</Accordion.Header>
                        <Accordion.Body>
                            Different sizes and types of companies have unique challenges and opportunities. By knowing more about your company, we can provide a more personalized assessment that reflects your specific needs.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>2. Provide Sector-Specific Insights</Accordion.Header>
                        <Accordion.Body>
                            Every sector has its own set of standards, regulations, and best practices. By understanding your sector, we can offer recommendations that are not only relevant but also compliant with industry-specific requirements.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>3. Facilitate Scalability and Growth</Accordion.Header>
                        <Accordion.Body>
                            Information about your company's size and type allows us to offer insights that support your current state and future growth. Whether you're a small startup or a large enterprise, our goal is to assist you in scaling your business continuity practices effectively.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>4. Provide Benchmarking and Comparison</Accordion.Header>
                        <Accordion.Body>
                            This information enables us to benchmark your company against others in your sector or of similar size. This comparative analysis can provide valuable insights into where you stand in your industry and areas for improvement.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Button variant="secondary" className="close-button" onClick={onClose}>
                    Sounds good, let's go!
                </Button>
                </div>

            </div>
        </div>
    );
};

export default InfoOverlay;
