/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getQuestions = /* GraphQL */ `
  query GetQuestions($id: ID!) {
    getQuestions(id: $id) {
      id
      assessmentId
      questionText
      answerOptions
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      assessmentsAssessmentQuestionsId
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assessmentId
        questionText
        answerOptions
        createdAt
        updatedAt
        assessmentsAssessmentQuestionsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoUserId
      userEmail
      firstName
      lastName
      userPhone
      userMobile
      userCompany {
        id
        cognitoUserId
        companyName
        companyType
        sector
        country
        website
        phoneNumber
        turnoverBracket
        companyHubspotId
        createdAt
        updatedAt
        owner
        __typename
      }
      companyId
      userTitle
      linkedIn
      userProfilePic
      hubspotId
      userResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      companyUsersId
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        userEmail
        firstName
        lastName
        userPhone
        userMobile
        companyId
        userTitle
        linkedIn
        userProfilePic
        hubspotId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      cognitoUserId
      companyName
      companyType
      sector
      country
      website
      phoneNumber
      turnoverBracket
      companyHubspotId
      users {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        companyName
        companyType
        sector
        country
        website
        phoneNumber
        turnoverBracket
        companyHubspotId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getResults = /* GraphQL */ `
  query GetResults($id: ID!) {
    getResults(id: $id) {
      id
      cognitoUserId
      user {
        id
        cognitoUserId
        userEmail
        firstName
        lastName
        userPhone
        userMobile
        companyId
        userTitle
        linkedIn
        userProfilePic
        hubspotId
        createdAt
        updatedAt
        companyUsersId
        owner
        __typename
      }
      userId
      assessment {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      assessmentId
      dateCompleted
      assessmentAnswers
      assessmentScore
      maturityLevel
      executiveSummary
      assessmentSuggestions
      createdAt
      updatedAt
      assessmentsAssessmentResultsId
      userUserResultsId
      owner
      __typename
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoUserId
        userId
        assessmentId
        dateCompleted
        assessmentAnswers
        assessmentScore
        maturityLevel
        executiveSummary
        assessmentSuggestions
        createdAt
        updatedAt
        assessmentsAssessmentResultsId
        userUserResultsId
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const companiesByCognitoUserIdAndCompanyName = /* GraphQL */ `
  query CompaniesByCognitoUserIdAndCompanyName(
    $cognitoUserId: String!
    $companyName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companiesByCognitoUserIdAndCompanyName(
      cognitoUserId: $cognitoUserId
      companyName: $companyName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoUserId
        companyName
        companyType
        sector
        country
        website
        phoneNumber
        turnoverBracket
        companyHubspotId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAssessments = /* GraphQL */ `
  query GetAssessments($id: ID!) {
    getAssessments(id: $id) {
      id
      assessmentName
      assessmentType
      assessmentDescription
      assessmentSlug
      assessmentOverview
      assessmentCompletionTime
      assessmentSrc
      assessmentQuestions {
        nextToken
        __typename
      }
      assessmentResults {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        assessmentName
        assessmentType
        assessmentDescription
        assessmentSlug
        assessmentOverview
        assessmentCompletionTime
        assessmentSrc
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
