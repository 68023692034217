// Home.js
import React from 'react';

const Home = () => {
    return (
        <div style={{ textAlign: 'center', margin: '20px' }}>
            <h1>Welcome to the Home Page</h1>
            <p>This is a simple home page for your application.</p>
            <p>You can add more content here as needed.</p>
        </div>
    );
};

export default Home;
