import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import awsconfig from './aws-exports';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../node_modules/@aws-amplify/ui-react/dist/styles.css'; // Adjust the path as per your project structure
import { Authenticator } from '@aws-amplify/ui-react';
import { AuthContextProvider } from './contexts/AuthContext';
import { Amplify, Auth } from 'aws-amplify';

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

      <Authenticator.Provider>
      <AuthContextProvider>



    <Router>
    <App />
    </Router>
    </AuthContextProvider>

    </Authenticator.Provider>


);


serviceWorker.unregister();