// Profile.js
import React, { useState, useEffect, useMemo } from 'react';
import { generateClient } from 'aws-amplify/api';
import { getUser, listCompanies } from '../graphql/queries';
import { Link } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import Header from './Header';

const Profile = () => {
    const [userRecord, setUserRecord] = useState({});
    const [companyRecord, setCompanyRecord] = useState({});
    const [loading, setLoading] = useState(true);
    const { userAttributes } = useUser();

    // Memoize the API instance to ensure it remains stable between renders
    const API = useMemo(() => generateClient(), []);

    const isRenderable = (value) => {
        return value !== null && value !== undefined;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userAttributes?.sub) {
                    const cognitoUserId = userAttributes.sub;

                    // Fetch User Data
                    const userData = await API.graphql({ query: getUser, variables: { id: cognitoUserId } });
                    if (userData.data.getUser) {
                        setUserRecord(userData.data.getUser);
                    }

                    // Fetch Company Data
                    const companyData = await API.graphql({
                        query: listCompanies,
                        variables: { filter: { cognitoUserId: { eq: cognitoUserId } } }
                    });
                    if (companyData.data.listCompanies.items.length > 0) {
                        setCompanyRecord(companyData.data.listCompanies.items[0]);
                    }
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userAttributes?.sub]); // Only re-run when the user's sub attribute changes

    if (loading) {
        return <div className="container mt-4">Loading...</div>;
    }

    return (
        <>
            <Header
                pageTitle="User and Company Information"
                tagline="Empower Your Insights: Accurate Profile Information Fuels Precise Report Generation. To elevate your analysis please provide as much information as you feel comfortable with."
            />
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <h3>User Information</h3>
                        <table className="table">
                            <tbody>
                                {Object.entries(userRecord).map(([key, value]) => {
                                    if (!isRenderable(value)) {
                                        return null;
                                    }
                                    return (
                                        <tr key={key}>
                                            <td><strong>{key}</strong></td>
                                            <td>{value?.toString() || 'N/A'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Link to="/profile/user" className="btn btn-primary">Edit User</Link>
                    </div>
                    <div className="col-md-6">
                        <h3>Company Information</h3>
                        <table className="table">
                            <tbody>
                                {Object.entries(companyRecord).map(([key, value]) => {
                                    if (!isRenderable(value)) {
                                        return null;
                                    }
                                    return (
                                        <tr key={key}>
                                            <td><strong>{key}</strong></td>
                                            <td>{value?.toString() || 'N/A'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <Link to="/profile/company" className="btn btn-primary">Edit Company</Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;