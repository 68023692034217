// CompanyForm.js
import React, { useState, useEffect } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { createCompany, updateCompany } from '../../graphql/mutations';
import { listCompanies, getCompany } from '../../graphql/queries'; // Import your GraphQL queries and mutations
import companyData from './companyData.json';

const CompanyForm = () => {
    const [companyRecord, setCompanyRecord] = useState({
        companyName: '',
        companyType: '',
        sector: '',
        country: '',
        website: '',
        phoneNumber: '',
        turnoverBracket: '',
        companyHubspotId: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const API = generateClient();

    useEffect(() => {
        handleFetchAndSaveCompanyRecord();
    }, []);

    const handleFetchAndSaveCompanyRecord = async () => {
      try {
          const attributes = await fetchUserAttributes();
          const cognitoUserId = attributes.sub;

          // Use the companies query with a filter
          let companyData = await API.graphql({
              query: listCompanies,
              variables: { filter: { cognitoUserId: { eq: cognitoUserId } } }
          });
          let companies = companyData.data.listCompanies.items;

          let company;
          if (companies.length === 0) {
              // Create a new company record
              const newCompanyData = await API.graphql({
                  query: createCompany,
                  variables: { input: { cognitoUserId } }
              });
              company = newCompanyData.data.createCompany;
          } else {
              // Use the first returned company
              company = companies[0];
          }

          setCompanyRecord(company);
      } catch (err) {
          console.error('Error:', err);
          setError(`Error: ${err.message || JSON.stringify(err)}`);
      } finally {
          setLoading(false);
      }
  };

    const handleInputChange = (e) => {
        setCompanyRecord({ ...companyRecord, [e.target.name]: e.target.value });
    };

    const handleUpdateCompany = async (e) => {
        e.preventDefault();
        try {
            if (!companyRecord.id) {
                throw new Error("Company ID is missing. Cannot update company.");
            }

            const updateCompanyInput = {
                id: companyRecord.id,
                companyName: companyRecord.companyName,
                companyType: companyRecord.companyType,
                sector: companyRecord.sector,
                country: companyRecord.country,
                website: companyRecord.website,
                phoneNumber: companyRecord.phoneNumber,
                turnoverBracket: companyRecord.turnoverBracket,
                companyHubspotId: companyRecord.companyHubspotId,
            };

            await API.graphql({ query: updateCompany, variables: { input: updateCompanyInput } });
            // Optionally handle the response or update the state as needed
        } catch (err) {
            console.error('Error updating company record:', err);
            setError(`Error: ${err.message || JSON.stringify(err)}`);
        }
    };

    if (loading) {
        return <div className="container mt-4">Loading...</div>;
    }

    if (error) {
        return <div className="container mt-4 alert alert-danger">{error}</div>;
    }

    return (
      
        <div className="container mt-4">
            <h2>Company Profile</h2>
            <form onSubmit={handleUpdateCompany}>
    {/* Company Name Input */}
    <div className="mb-3">
        <label htmlFor="companyName" className="form-label">Company Name</label>
        <input type="text" className="form-control" id="companyName" name="companyName" value={companyRecord.companyName} onChange={handleInputChange} />
    </div>

    {/* Company Type Dropdown */}
    <div className="mb-3">
        <label htmlFor="companyType" className="form-label">Company Type</label>
        <select className="form-control" id="companyType" name="companyType" value={companyRecord.companyType} onChange={handleInputChange}>
            <option value="">Select Company Type</option>
            {companyData.companyTypes.map(type => (
                <option key={type} value={type}>{type}</option>
            ))}
        </select>
    </div>

    {/* Sector Dropdown */}
    <div className="mb-3">
        <label htmlFor="sector" className="form-label">Sector</label>
        <select className="form-control" id="sector" name="sector" value={companyRecord.sector} onChange={handleInputChange}>
            <option value="">Select Sector</option>
            {companyData.companySectors.map(sector => (
                <option key={sector} value={sector}>{sector}</option>
            ))}
        </select>
    </div>

    {/* Country Dropdown */}
    <div className="mb-3">
        <label htmlFor="country" className="form-label">Country</label>
        <select className="form-control" id="country" name="country" value={companyRecord.country} onChange={handleInputChange}>
            <option value="">Select Country</option>
            {companyData.commonCountries.map(country => (
                <option key={country} value={country}>{country}</option>
            ))}
            <option value="Other">Other</option>
        </select>
    </div>

    {/* All Countries Dropdown (Conditional) */}
    {companyRecord.country === "Other" && (
        <div className="mb-3">
            <label htmlFor="allCountries" className="form-label">All Countries</label>
            <select className="form-control" id="allCountries" name="country" value={companyRecord.country} onChange={handleInputChange}>
                <option value="">Select Country</option>
                {companyData.allCountries.map(country => (
                    <option key={country} value={country}>{country}</option>
                ))}
            </select>
        </div>
    )}

    {/* Website Input */}
    <div className="mb-3">
        <label htmlFor="website" className="form-label">Website</label>
        <input type="text" className="form-control" id="website" name="website" value={companyRecord.website} onChange={handleInputChange} />
    </div>

    {/* Phone Number Input */}
    <div className="mb-3">
        <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
        <input type="text" className="form-control" id="phoneNumber" name="phoneNumber" value={companyRecord.phoneNumber} onChange={handleInputChange} />
    </div>

    {/* Turnover Bracket Dropdown */}
    <div className="mb-3">
        <label htmlFor="turnoverBracket" className="form-label">Turnover Bracket</label>
        <select className="form-control" id="turnoverBracket" name="turnoverBracket" value={companyRecord.turnoverBracket} onChange={handleInputChange}>
            <option value="">Select Turnover Bracket</option>
            {companyData.turnoverBrackets.map(bracket => (
                <option key={bracket} value={bracket}>{bracket}</option>
            ))}
        </select>
    </div>

    {/* Company HubSpot ID Input */}
    <div className="mb-3">
        <label htmlFor="companyHubspotId" className="form-label">Company HubSpot ID</label>
        <input type="text" className="form-control" id="companyHubspotId" name="companyHubspotId" value={companyRecord.companyHubspotId} onChange={handleInputChange} />
    </div>

    {/* Submit Button */}
    <div className="mb-3">
        <button type="submit" className="btn btn-primary">Update Company</button>
    </div>
</form>

            
        </div>
        
        
    );
};

export default CompanyForm;
