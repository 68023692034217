import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { listCompanies, getUser } from '../graphql/queries'; // Import queries and mutation
import { createCompany } from '../graphql/mutations';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userAttributes, setUserAttributes] = useState(null);
    const [companyRecord, setCompanyRecord] = useState(null);
    const [userRecord, setUserRecord] = useState(null);
    const navigate = useNavigate(); // useNavigate hook for redirection
    const API = generateClient();

    const fetchAndSetUserAttributes = async () => {
        console.log('Fetching user attributes...');
        try {
            const attributes = await fetchUserAttributes();
            console.log('User attributes received:', attributes);
            setUserAttributes(attributes);

            // Fetch Company Data
            if (attributes?.sub) {
                const companyData = await API.graphql({
                    query: listCompanies,
                    variables: { filter: { cognitoUserId: { eq: attributes.sub } } }
                });

                const companies = companyData.data.listCompanies.items;
                if (companies.length > 0) {
                    console.log('Company data received:', companies);
                    setCompanyRecord(companies[0]);
                } else {
                    // Create a new company record
                    console.log('No company record found for user, creating one...');
                    const newCompanyData = await API.graphql({
                        query: createCompany,
                        variables: { input: { cognitoUserId: attributes.sub } }
                    });
                    setCompanyRecord(newCompanyData.data.createCompany);
                    console.log('New company record created:', newCompanyData.data.createCompany);

                    // Redirect to /profile/onboarding
                    navigate('/profile/onboarding');
                }

                // Fetch User Data
                const userData = await API.graphql({ query: getUser, variables: { id: attributes.sub } });
                if (userData.data.getUser) {
                    console.log('User data received:', userData.data.getUser);
                    setUserRecord(userData.data.getUser);
                } else {
                    console.log('No user record found for ID:', attributes.sub);
                }
            }
        } catch (error) {
            console.error('Error fetching user attributes, company data, and user data:', error);
        }
    };

    useEffect(() => {
        fetchAndSetUserAttributes();
    }, []);

    return (
        <UserContext.Provider value={{ userAttributes, companyRecord, userRecord, setUserAttributes, setCompanyRecord, setUserRecord }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
